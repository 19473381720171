<template>
  <v-container class="lighten-5 container">
    <v-row no-gutters>
      <v-col>
        <v-card class="mx-auto ml-1 mr-1">

          <v-card-title>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-3" color="secundary" v-bind="attrs" v-on="on">
                  mdi-subtitles
                </v-icon>
              </template>
              <span>Títulos relacionados en base a palabras clave</span>
            </v-tooltip> Noticias sobre {{this.$route.params.palabra.charAt(0).toUpperCase() +this.$route.params.palabra.slice(1)}} hoy en Argentina
          </v-card-title>

        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">
          <v-card-text>

            <div v-for="rel in noticia_buscar" v-bind:key="rel.Id">

              <router-link class="link" :to="{ path:'/general/'+rel.Medio+'/'+limpiaTexto(rel.Titulo)+'/'+rel.Id}">
                <v-text-field v-html="`<h3><strong style='color:#2196f3;'>`+rel.Medio+`:`+`&nbsp; </strong> `+rel.Titulo+`</h3>`" />
                <v-text-field v-html="`<h5>&nbsp;`+rel.Fecha_ar+`</h5>`" />
              </router-link>
              <v-divider class="mx-4 mt-2"></v-divider>
            </div>
            <template>
              <div class="text-center">
                <v-pagination @input="gotopage()" v-model="page" :total-visible="7" :length="noticia_num_resultados"></v-pagination>
              </div>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>

import axios from "axios";
export default {
  name: "TituloB",

  data: () => ({
    noticia_buscar: [],
    noticia_num_resultados:0,
    page: 0,
  }),
  
  async mounted() {
    
    try {
      const res = await axios.post(
        "https://titulos.com.ar/buscar_json.php",
        "palabra=" + this.$route.params.palabra + "&pagina=" + this.$route.params.pagina
      );

      console.log(isNaN(Number(this.$route.params.pagina)));
      if(isNaN(Number(this.$route.params.pagina))){
        this.page = 1;
      }else{
        this.page = Number(this.$route.params.pagina);
        
      }
      
      this.noticia_buscar = res.data.resultados.buscar[0];
      this.noticia_num_resultados = parseInt(res.data.resultados.numresultados/5);
    } catch (e) {
      console.error(e);
    }
    
  },

  methods: {
    gotopage(){
      this.$router.push("/buscar/" + this.$route.params.palabra + "/" + this.page);
      //console.log("si");
    },
    limpiaTexto(titulo) {
      titulo = titulo.replace(/[*.+?#&%;:|^$()[\]{}\\]/g, "");
      titulo = titulo.replace(/[\s]/g, "-");
      titulo = titulo.toLowerCase();
      titulo = titulo.replace(/,;/g, "");
      titulo = titulo.replace(/"/g, "");

      titulo = titulo.substring(0, 100);
      return titulo;
    },
  },
};
</script>



<style scoped>
.v-application .text-subtitle-1 {
  line-height: 2rem !important;
}

.v-card__subtitle,
.v-card__text {
  padding: 25px !important;
}

.v-card__title {
  padding: 15px !important;
}
.iframe {
  background: #ffff;
}
.foto-despliegue {
  width: "100%";
}
.img-redondeada {
  width: 350px;
}
@media (min-width: 400px) and (max-width: 700px) {
  .fixed-container {
    float: none;
  }
  .img-redondeada {
    width: 100%;
  }
}
</style>