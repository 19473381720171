<template>
  <div>    
    <Buscar />
    <MasLeidas />
    <Noticias1 />
    <Noticias2 />
    <Noticias3 />
    <Noticias4 />
    <Noticias5 />

  </div>
</template>

<script>

import Buscar from "@/components/buscar.vue";
import MasLeidas from "@/components/mas-leidas.vue";
import Noticias1 from "@/components/noticias-1.vue";
import Noticias2 from "@/components/noticias-2.vue";
import Noticias3 from "@/components/noticias-3.vue";
import Noticias4 from "@/components/noticias-4.vue";
import Noticias5 from "@/components/noticias-5.vue";

export default {
  inheritAttrs: false,
  name: "BuscarTitulo",
  props: ["palabra"],
  components: {
 
    Buscar,
    MasLeidas,
    Noticias1,
    Noticias2,
    Noticias3,
    Noticias4,
    Noticias5,
  },
  data() {
    return {
      show: true,
      componentKey: 0,
    };
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.componentKey += 1;
      }
    },
  },
};
</script>